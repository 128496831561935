import React, { useState } from "react";
import useTerms from "../../../../hooks/useTerms";
import Select from 'react-select'
import { CURRENT_VET_YEAR_LABEL, CURRENT_VET_YEAR_VALUE } from "../../../../utils/constants";
import { config } from "../../../../utils/config";
import axios from "axios";

/**
 * Create the objects for select options.
 *
 * The value cannot be an object, therefore we stringify the object.
 *
 * @param {Object[]} data - eg [{year: 2024, term: 'HT3'}]
 * @returns {Object[]} - eg [{ label: '2024.HT3', value: '{"year":2024,"term":"HT3"}'}]
 */
function createOptions(data) {
    return data?.map((term) => {
        return {
            value: JSON.stringify({ year: parseInt(term.year), term: term.term }),
            label: `${term.year === CURRENT_VET_YEAR_VALUE ? CURRENT_VET_YEAR_LABEL : term.year}.${term.term}`
        };
    }) || [];
}

function DailyDataSummaryForm({ initialValues }) {
    const [selTerms, setSelTerms] = useState(createOptions(initialValues?.daily_data_summary_terms));
    const [isSubmitting, setSubmitting] = useState(false);
    const { data, isLoading, isError, isSuccess } = useTerms();

    const handleSubmit = () => {
        setSubmitting(true);
        const values = selTerms?.map(({ value }) => {
            try {
                return JSON.parse(value);
            } catch {
                console.error("Failed to parse value:", value);
                return null;
            }
        }).filter(Boolean) || [];

        axios.post(`${config.system.baseApiUrl}/config/daily-data-summary-terms`, { 'daily_data_summary_terms': values })
            .catch((error) => console.error("Submission failed:", error))
            .finally(() => setSubmitting(false));
    };

    return (
        <section>
            <h4>
                Daily Data Summary
            </h4>
            <table style={{ width: '18rem' }} className="oddEvenRows">
                <tbody>
                    <tr>
                        <td>
                            <Select
                                isMulti
                                isDisabled={isLoading || isError || data.length === 0}
                                isLoading={isLoading}
                                options={isSuccess ? createOptions(data) : []}
                                value={selTerms}
                                placeholder="Terms ..."
                                onChange={setSelTerms}
                                classNamePrefix="react-select"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Select the terms you want the daily data summary to check/present.
                            <br/><br/>
                            For each configured term the last succeeded import log is fetched. From this cohort of import logs, the
                            oldest timestamps are returned. These are the timestamps the daily data summary presents to the users.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button disabled={isSubmitting || !isSuccess} onClick={handleSubmit}>{isSubmitting ? 'Submitting ...' : 'Submit'}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
}

export { DailyDataSummaryForm };